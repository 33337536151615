<template>
    <div>
        <div class="bg-white">
            <div class="topbox flex align-center justify-between">
                <div class="flex align-center">
                    <img src="/tempimg/logo.png" alt="">
                    <div class="menu flex align-center margin-left">
                        <span class="on" @click="$router.push('/user/tempIndex')">首页</span>
                        <span @click="$router.push('/user/index')">就业通</span>
                    </div>
                </div>
                <div class="flex align-center">
                    <span>欢迎来到面试大厅！</span>
                </div>
            </div>
        </div>
        <div class="interviewbox bg-white">
            <div class="interviewtop flex align-center justify-between">
                <div class="flex align-center padding-lr-sm">
                    <el-avatar shape="square" size="large" src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-avatar>
                    <span class="margin-left-sm text-xlx text-black text-bold">{{ roomInfo.workName }} - 招聘大厅({{userList.length>8?8:userList.length}}/12)</span>
<!--                    <span class="margin-left-sm text-xlx text-black text-bold">{{ roomInfo.memberName }} - 面试大厅({{userList.length>8?8:userList.length}}/8)</span>-->
                </div>
                <div class="margin-right-sm">
                    <el-button type="primary" size="medium" @click="loginOutdt" >退出招聘大厅</el-button>
                </div>
            </div>
            <div class="flex align-center">
                <div class="intleft">
<!--                    <div class="flex align-center padding-sm">-->
<!--                        <span class="topspan bg-blue text-white  radius">{{roomInfo.status}}</span>-->
<!--                        <span class="margin-left-sm">{{ roomInfo.workName }}</span>-->
<!--                    </div>-->
<!--                    <div class="flex align-center justify-center " style="margin-top: 80px; margin-bottom: 10px;" >-->
<!--                        <div class="personbox">-->
<!--                            <div class="flex align-center justify-center" style="margin-bottom: 5px;" @click="gotoCompanydetail"><el-avatar shape="square" :size="46" :src="roomInfo.memberImg?roomInfo.memberImg:defaultHead"></el-avatar></div>-->
<!--                            <span class="personspan" @click="gotoCompanydetail">{{ roomInfo.memberName }}</span>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="interpersonbox">
                        <div class="personbox" v-for="(item,index) in 12">
                            <template v-if="userList[index]?.name">
                                <div @click="showUser(userList[index])"><el-avatar shape="square" fit="cover" :size="46" :src="userList[index].img?userList[index].img:'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png'"></el-avatar></div>
                                <span class="personspan text-cut" style=" display: block; ">{{ userList[index]?.name }}</span>
                            </template>
                            <template v-else>
                                <div>
                                    <img v-if="index<=3" style="width: 46px; height: 46px; transform: rotate(-90deg)" src="/img/zw.png" alt="">
                                    <img v-if="index==4 || index==6" style="width: 46px; height: 46px; transform: rotate(180deg)" src="/img/zw.png" alt="">
                                    <img v-if="index==5 || index==7" style="width: 46px; height: 46px;" src="/img/zw.png" alt="">
                                    <img v-if="index>7" style="width: 46px; height: 46px;transform: rotate(90deg)" src="/img/zw.png" alt="">
                                </div>
                                <span class="personspan text-cut" style="color: #43cf7c;">空闲中</span>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="intright">
                    <div class="righttop">
                        <div class="rightcont">
                            <template v-for="(item,index) in list">
                                <div class="message flex align-start messageleft margin-bottom-sm" >
                                    <el-avatar shape="square"  style="flex: 0 0 30px;" :size="30" :src="item.userImg?item.userImg:defaultHead"></el-avatar>
                                    <div class=" text-xs  margin-left-xs">
                                        <div class="text-gray">{{item.userName}} {{item.createTime}}</div>
                                        <div class="messagecont">{{ item.message }}</div>
                                    </div>
                                </div>
<!--                                <div class="message flex align-start justify-end messageright margin-bottom-sm" v-if="item.isSelf==true">-->
<!--                                    <div class=" text-xs  margin-right-xs">-->
<!--                                        <div class="text-gray text-right">{{item.createTime}} {{item.userName}}</div>-->
<!--                                        <div class="messagecont">{{ item.message }}</div>-->
<!--                                    </div>-->
<!--                                    <el-avatar shape="square" style="flex: 0 0 30px;" :size="30" :src="item.userImg?item.userImg:defaultHead"></el-avatar>-->
<!--                                </div>-->
                            </template>
                        </div>
                    </div>
                    <div class="rightbot">
                        <div class="fastsend flex align-center">
                            <span @click="fastSend('贵公司待遇多少呢？')">贵公司待遇多少呢？</span>
                            <span @click="fastSend('有5险1金吗？')">有5险1金吗？</span>
                            <span @click="fastSend('周末是双休的吗？')">周末是双休的吗？</span>
                        </div>
                        <div class="padding-xs">
                            <el-input
                                type="textarea"
                                :rows="3"
                                style="border: none;"
                                placeholder="请输入内容"
                                v-model="form.message">
                            </el-input>
                        </div>
                        <div class="padding-lr-xs flex justify-end">
                            <el-button type="primary" @click="sendMess">发送</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Showresum v-if="show" :show.sync="show" :id="showUserid" />
    </div>
</template>
<script>
import _api from '@/api/index'
import Showresum from './showResum'

export default {
    components:{
        Showresum,
    },
    data(){
        return{
            show:false,
            showUserid:null,
            defaultHead:"https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
            roomInfo:{
                memberId:null,
                memberImg:null,
                memberName:null,
                status:null,
                workName:null
            },
            roleId:null,
            sendRole:null,
            userInfo:JSON.parse(localStorage.getItem('userInfo')),
            id:null,
            userList:[],//用户列表
            timeInter:null,//定时器1
            timeInterlist:null,//聊天记录定时器
            list:[

            ],
            form:{
                message:null,
            }
        }
    },
    created() {

        this.id=this.$route.query.workId*1
        this.roleId=this.userInfo.roleId
        this.sendRole=this.roleId==99?1:2
        this.joinToVoice()
        this.getRoomInfo()
        this.getList()
        this.setInter1()


    },
    beforeCreate () {
        // 例如设置为红色
        document.querySelector('body').setAttribute('style', 'background-color:#023274;')

    },
    beforeDestroy() {
        clearInterval(this.timeInter)
        clearInterval(this.timeInterlist)
    },
    methods:{
        loginOutdt(){//退出招聘大厅
            this.$confirm('确定要退出招聘大厅吗?', '提示', {
                confirmButtonText: '确定退出',
                cancelButtonText: '保持在线',
                type: 'warning'
            }).then(() => {
                console.log(99)
                let apiurl
                apiurl=this.roleId===99?'/app/voice/leaveVoice':'/voice/leaveVoice'
                _api.get(apiurl+"/"+this.id).then(res=>{
                    if(res.code===200){
                        this.$router.push("/user/tempIndex")
                    }
                })
            }).catch(() => {
                console.log(56)
                this.$router.push("/user/tempIndex")
            });
        },
        showUser(row){//打开简历
            console.log(887)
            if(row.roleId!=2 && this.roleId==2){
                this.showUserid=row.resumeId
                this.show=true
            }

        },
        setInter1(){
            this.timeInter=setInterval(()=>{
                this.getUserList()
            },2000)
            this.timeInterlist=setInterval(()=>{
                this.getList()
            },1000)
        },
        joinToVoice(){//保持在线
            let apiurl
            apiurl=this.roleId===99?'/app/voice/joinToVoice':'/voice/joinToVoice'
            _api.get(apiurl+"/"+this.id).then(res=>{
                if(res.code===200){
                    this.getUserList()
                }
            })
        },
        getRoomInfo(){//获取房间信息
            let apiurl
            apiurl=this.roleId===99?'/app/voice/getRoomInfo':'/voice/getRoomInfo'
            _api.get(apiurl+"/"+this.id).then(res=>{
                if(res.code===200){
                    for(let k in this.roomInfo){
                        this.roomInfo[k]=res.data[k]
                    }
                }
            })
        },
        getUserList(){//获取用户列表
            let apiurl
            apiurl=this.roleId===99?'/app/voice/getRoomUserList':'/voice/getRoomUserList'

            _api.get(apiurl+"/"+this.id).then(res=>{
                if(res.code===200){
                    this.userList=res.data
                }
            })


        },
        getList(){//获取信息
            let apiurl
            apiurl=this.roleId===99?'/app/voice/getMessList':'/voice/getMessList'
            _api.get(apiurl+"/"+this.id).then(res=>{
                if(res.code===200){
                    this.list=res.data
                }
            })
        },
        fastSend(val){
            let apiurl
            apiurl=this.roleId===99?'/app/voice/sendMessage':'/voice/sendMessage'
            _api.post(apiurl,{message:val,workId:this.id,userId:this.userInfo.id,roleId:this.sendRole}).then(res=>{
                if(res.code===200){
                }
            })
        },
        sendMess(){//发送信息
            let apiurl
            apiurl=this.roleId===99?'/app/voice/sendMessage':'/voice/sendMessage'
            if(!this.form.message){
                this.$message.warning("内容不能为空！")
                return
            }
            _api.post(apiurl,{...this.form,workId:this.id,userId:this.userInfo.id,roleId:this.sendRole}).then(res=>{
                if(res.code===200){
                    this.form.message=null
                }
            })


        },
        gotoCompanydetail(){
            let routeData = this.$router.resolve({
                path: "/user/companyJobs",
                query: {companyId:this.roomInfo.memberId}
            });
            window.open(routeData.href, '_blank');
            // this.$router.push({path:"/user/companyJobs",query:{companyId:this.roomInfo.memberId}})

        }
    }
}
</script>
<style scoped>
.topbox{ height: 56px; width: 1180px; margin: 0 auto;}
.menu span{ display: block;padding: 0 16px; cursor: pointer;}
.menu span.on{color: #1678ff;}
.interviewbox{ width: 1180px; margin: 24px auto 0 auto; height: 706px; border-radius: 8px; overflow: hidden; }
.interviewtop{ height: 74px; background: linear-gradient(90deg, rgba(42, 130, 228, 0.2) 0%, rgba(204, 204, 204, 0) 100%); border-bottom: #bfbfbf solid 1px;}
.intleft{ width: 735px; height: 630px; border-right: #bfbfbf solid 1px;}
.intright{ width: 445px; height: 630px;}
 span.topspan{ display: block; padding: 3px 10px; border-radius: 20px;}
 span.personspan{ color: #ffffff; background: #333333; padding: 1px 8px; font-size: 12px; zoom: 0.9; border-radius: 12px;}
 .interpersonbox{ height: 460px; margin-top: 50px;  position: relative; background: url("../../../assets/dmbg.png") no-repeat center center;}
 .personbox{ width: 150px; display: flex; overflow: hidden; flex-direction: column; align-items: center; justify-content: center;}
.personbox span{ display: block;max-width: 150px; text-align: center;}
.interpersonbox .personbox:nth-child(1){top: 30px; left: 70px; position: absolute;}
.interpersonbox .personbox:nth-child(2){top: 30px; left: 230px; position: absolute;}
.interpersonbox .personbox:nth-child(3){top: 30px; left: 380px; position: absolute;}
.interpersonbox .personbox:nth-child(4){top: 30px; left: 530px; position: absolute;}
.interpersonbox .personbox:nth-child(5){top: 136px; left: 5px; position: absolute;}
.interpersonbox .personbox:nth-child(6){top: 136px; right: 5px; position: absolute;}
.interpersonbox .personbox:nth-child(7){top: 246px; left: 5px; position: absolute;}
.interpersonbox .personbox:nth-child(8){top: 246px; right: 5px; position: absolute;}
.interpersonbox .personbox:nth-child(9){top: 368px; left: 70px; position: absolute;}
.interpersonbox .personbox:nth-child(10){top: 368px; left: 230px; position: absolute;}
.interpersonbox .personbox:nth-child(11){top: 368px; left: 380px; position: absolute;}
.interpersonbox .personbox:nth-child(12){top: 368px; left: 530px; position: absolute;}
.righttop{ height: 440px; overflow-y: auto;}
.rightcont{ padding: 15px;}
.messageleft .messagecont{ background: #e5e5e5; margin-top: 5px; color: #333333; padding: 6px 10px; border-radius: 0 10px 10px 10px;}
.messageright .messagecont{ background:#d4e6fa;margin-top: 5px; color: #333333; padding: 6px 10px; line-height: 110%; color: #333333; border-radius: 10px 0 10px 10px;}
.rightbot{ border-top:#bfbfbf solid 1px; }
.fastsend{ padding-top: 5px; padding-left: 8px;}
.fastsend span{ display: block; margin: 5px 5px 0 0; padding: 5px 8px; font-size: 12px; background: #f0f0f0; border-radius: 12px; cursor:pointer;}







</style>

