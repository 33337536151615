<template>
    <div>
        <el-dialog
            title="简历"
            :visible.sync="show"
            width="60%"
            :before-close="handleClose">
            <div class="resumbox">
                <div class="have" v-if="loading">
                    <el-card class="box-card">
                        <div class="user-info flex">
                            <div class="headFigure">
                                <img :src="resumeDO.picUrl?resumeDO.picUrl:require('../../../assets/img/company/avata.png')" alt="">
                            </div>
                            <div class="base-info">
                                <div class="certificate flex">
                                    <span class="name">{{resumeDO.name}}</span>
                                    <span><i class="el-icon-mobile-phone"></i> 手机认证</span>
                                    <span><i class="el-icon-folder-checked"></i> 身份认证</span>
                                </div>
                                <div class="base-detail">
                                    <span class="sex">{{resumeDO.gender}} </span>
                                    <span class="icon-split-small"> | </span>
                                    <span class="age">{{resumeDO.age}}岁 </span>
                                    <span class="icon-split-small"> | </span>
                                    <span class="work1">工作经验: {{resumeDO.workExperience}} </span>
                                </div>
                                <div class="expectInfo">
                                    <div class="title-content flex">
                                        <div class="item-title">求职状态:</div>
                                        <div class="item-content">{{resumeDO.nowStatus}}</div>
                                    </div>
                                    <div class="title-content flex">
                                        <div class="item-title">期望职位:</div>
                                        <div class="item-content">{{resumeDO.expectPost}}</div>
                                    </div>
                                    <div class="title-content flex">
                                        <div class="item-title">期望薪资:</div>
                                        <div class="item-content">{{resumeDO.expectSalary}}</div>
                                    </div>
                                    <div class="title-content flex">
                                        <div class="item-title">手机号码:</div>
                                        <div class="item-content">{{resumeDO.linkerPhone}}</div>
                                    </div>
                                    <div class="title-content flex" v-if="resumeDO.vxNumber">
                                        <div class="item-title">微信号码:</div>
                                        <div class="item-content">{{resumeDO.vxNumber}}</div>
                                    </div>
                                    <div class="title-content flex" v-if="resumeDO.email">
                                        <div class="item-title">联系邮箱:</div>
                                        <div class="item-content">{{resumeDO.email}}</div>
                                    </div>
                                </div>
<!--                                <div class="getContact-row">-->
<!--                                    <el-button type="primary" @click="openInviteBox" size="small" :disabled="interviewWorkIds.length>0">邀请面试</el-button>-->
<!--                                    <template>-->
<!--                                        <el-button type="success" v-if="isCollection" @click="collectResumes" size="small">收藏简历</el-button>-->
<!--                                        <el-button type="success" v-else @click="deleteResume" size="small">取消收藏</el-button>-->
<!--                                    </template>-->
<!--                                    <el-button type="warning" :disabled="!isDownLoad" size="small" @click="downLoadUserResume">下载简历</el-button>-->
<!--                                    &lt;!&ndash; <el-button class="success button" :disabled="!isCollection" @click="collectResumes">收藏简历</el-button> &ndash;&gt;-->
<!--                                </div>-->
                            </div>
                        </div>
                        <el-dialog
                            title="邀请面试"
                            :visible.sync="inviteVisible"
                            width="550px">
                            <div class="messageInfo">
                                <el-form ref="inviteFormRef" :model="inviteForm" :rules="inviteRules" label-width="100px">
                                    <el-form-item label="受邀人:">
                                        <el-input v-model="inviteForm.userName" disabled size="small" style="width:350px"></el-input>
                                    </el-form-item>
                                    <el-form-item label="面试时间:" prop="beginTime">
                                        <el-date-picker
                                            v-model="inviteForm.beginTime"
                                            size="small"
                                            format="yyyy-MM-dd HH:mm:ss"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            default-time="12:00:00"
                                            :picker-options="pickerOptions"
                                            style="width:350px"
                                            type="datetime"
                                            placeholder="选择面试时间">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="面试职位:" prop="workId">
                                        <el-select v-model="inviteForm.workId" placeholder="请选择邀约职位" size="small" style="width:350px">
                                            <el-option
                                                v-for="item in jobList"
                                                :key="item.id"
                                                :label="item.workName"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="公司地址:" prop="address">
                                        <el-input v-model="inviteForm.address" size="small" style="width:350px" placeholder="请输入公司地址"></el-input>
                                    </el-form-item>
                                    <el-form-item label="联系人:" prop="contacts">
                                        <el-input v-model="inviteForm.contacts" size="small" style="width:350px" placeholder="请输入公司联系人姓名"></el-input>
                                    </el-form-item>
                                    <el-form-item label="联系电话:" prop="contactsPhone">
                                        <el-input v-model="inviteForm.contactsPhone" size="small" style="width:350px" placeholder="请输入公司联系人号码"></el-input>
                                    </el-form-item>
                                </el-form>

                            </div>
                            <span slot="footer" class="dialog-footer">
                        <el-button @click="inviteVisible = false">取 消</el-button>
                        <el-button type="primary" @click="invited" :loading="clickStatus">确 定</el-button>
                    </span>
                        </el-dialog>
                        <!-- <div class="jobInform flex">
                            <div class="latest-week2week flex">
                                <div class="latest2weeks-icon"><img src="../../../assets/img/company/latest2weeks.png" alt="" srcset=""></div>
                                <div>进两周</div>
                            </div>
                            <div class="activety-level flex">
                                <div class="level-icon">
                                    <el-progress type="circle" :percentage="25"></el-progress>
                                </div>
                            </div>
                        </div> -->
                    </el-card>
                    <el-card class="box-card">
                        <div class="industry" v-if="resumeDO.tags.length>0">
                            <div class="industry-title title1">职业优势</div>
                            <div class="tag-choose">
                                <el-tag type="success" v-for="(item, index) in resumeDO.tags" :key="`tag${index}`">{{item}}</el-tag>
                            </div>
                        </div>
                        <div class="work" v-if="resumeWorkExperienceDOS.length>0">
                            <div class="title1">工作经历 </div>
                            <div class="work-detail" v-for="(item, index) in resumeWorkExperienceDOS" :key="`resumeWorkExperienceDOS${index}`">
                                <div class="itemName">{{item.companyName}}</div>
                                <div class="project-content">
                                    <p>工作时间：{{item.startTime}} 至 {{item.endTime}}</p>
                                    <p>薪资水平：{{item.salary}}</p>
                                    <p>担任职位：{{item.position}}</p>
                                    <pre>工作职责：{{item.dutyContent}}</pre>
                                </div>
                            </div>
                        </div>
                        <div class="work project" v-if="resumeProjectExperienceDO.length>0">
                            <div class="title1">项目经历 </div>
                            <div class="work-detail" v-for="(item, index) in resumeProjectExperienceDO" :key="`resumeProjectExperienceDO${index}`">
                                <div class="itemName">{{item.projectName}}</div>
                                <div class="project-content">
                                    <p>工作时间：{{item.startTime}} 至 {{item.endTime}}</p>
                                    <p>担任职位：{{item.position}}</p>
                                    <pre>工作职责：{{item.projectDesc}}</pre>
                                </div>
                            </div>
                        </div>
                        <div class="education" v-if="resumeEductionExperienceDO.length>0">
                            <div class="title1">教育经历 </div>
                            <div class="edu-detail" v-for="(item, index) in resumeEductionExperienceDO" :key="`resumeEduction${index}`">
                                <div class="school">
                                    <span class="college-name">{{item.schoolName}}</span>
                                    <span class="splitLine-edu">|</span>
                                    <span class="graduate-time">{{item.startTime}} 至 {{item.endTime}}</span>
                                </div>
                                <div class="professional">{{item.major}}</div>
                            </div>
                        </div>
                        <div class="aboutMe">
                            <div class="title1">自我评价 </div>
                            <pre class="about-detail">{{resumeDO.selfEvaluation}}</pre>
                        </div>
                    </el-card>
                </div>
                <div class="no" v-else>
                    <img src="../../../assets/img/company/loading.png" alt="" srcset="" class="loading-img">
                    <p>正在加载中...</p>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">关闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import companyRequest from '../../../api/company'
// 验证手机号的规则
let checkMobile = (rule, value, cb) => {
    const regMobile = /^1[0-9]{10}$/

    if (regMobile.test(value)) {
        return cb()
    }
    cb(new Error('请输入合法的手机号'))
}
export default{
    props:{
        show:{
            type:Boolean,
            default:false
        },
        id:{
            type:Number,
            default: null
        }
    },
    created() {
        this.userInfo=JSON.parse(localStorage.getItem("userInfo"))
        this.inviteForm.contacts=this.userInfo.legalName
        this.inviteForm.contactsPhone=this.userInfo.phone
        this.resumeId = this.id
        // console.log('resumeId', this.resumeId)
        if (!this.resumeId) {
            this.$router.back()
        }
    },
    mounted() {
        this.getResumeInfo()
        this.getFilterJobList()
    },
    data() {
        return {
            userInfo:null,
            inviteVisible: false, //邀请面试弹框
            resumeId: null, //简历id
            loading: false, //加载
            clickStatus: false,
            resumeDO: {}, //基本信息
            interviewWorkIds: [], //简历投递公司职位列表
            resumeEductionExperienceDO: [], //教育经历
            resumeProjectExperienceDO: [], //项目经历
            resumeWorkExperienceDOS: [], //工作经历
            isCollection: null, //是否可以收藏 false不可以
            isDownLoad: null, //是否可以下载 false 不可以
            jobList: [], //职位列表
            inviteForm: { //邀请面试表单
                userId: '', //用户id
                userName: '', //用户姓名
                workId: '', //职位id
                resumeId: '', //简历id
                beginTime: '', //面试时间
                address: '', //面试地址
                contacts: '', //联系人
                contactsPhone: '', //联系电话
                type: 0 // 0线下 1线上
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
                }
            },
            inviteRules: { //校验规则
                beginTime:[{ required: true, message: '请选择面试时间', trigger: 'change' },],
                workId:[{ required: true, message: '请选择邀约职位', trigger: 'change' },],
                address:[{ required: true, message: '请输入公司地址', trigger: 'blur' },],
                contacts:[{ required: true, message: '请输入公司联系人姓名', trigger: 'blur' },],
                contactsPhone:[{ required: true, message: '请输入公司联系人号码', trigger: 'blur' },  { validator: checkMobile, trigger: 'blur' }],
            },

        }
    },
    methods: {
        handleClose(){
            this.$emit("update:show",false)
        },
        //打开邀请面试弹框
        openInviteBox() {
            this.inviteForm.resumeId = this.resumeId
            this.inviteForm.userId = this.resumeDO.userId
            this.inviteForm.userName = this.resumeDO.name
            this.inviteVisible = true
            this.$nextTick(() => {
                this.$refs.inviteFormRef.clearValidate()
            })
        },
        //简历详细信息
        getResumeInfo() {
            companyRequest.getResumeInfo({resumeId: this.resumeId}).then((res) => {
                if (res.code === 200) {
                    setTimeout(() => {
                        this.loading = true
                    }, 300)
                    this.resumeDO = res.data.resumeDO
                    if (res.data.interviewWorkIds) {
                        this.interviewWorkIds = res.data.interviewWorkIds
                        console.log('interviewWorkIds11', this.interviewWorkIds)
                    } else {
                        this.interviewWorkIds = []
                        console.log('interviewWorkIds22', this.interviewWorkIds)
                    }
                    //  console.log('selfEvaluation', this.resumeDO.selfEvaluation)
                    // if (this.resumeDO.selfEvaluation) {
                    //     this.resumeDO.selfEvaluation = this.resumeDO.selfEvaluation.replace(/(' ')+/g,"")
                    // }
                    // console.log('selfEvaluation', this.resumeDO.selfEvaluation)
                    // this.resumeDO.tags = res.data.resumeDO.tag.split(',')
                    if (res.data.resumeDO.tag) {
                        this.resumeDO.tags = res.data.resumeDO.tag.split(',')
                    } else {
                        this.resumeDO.tags = []
                    }
                    this.isCollection= res.data.isCollection
                    this.isDownLoad = res.data.isDownLoad
                    this.resumeEductionExperienceDO = res.data.resumeEductionExperienceDO
                    this.resumeWorkExperienceDOS = res.data.resumeWorkExperienceDOS
                    this.resumeProjectExperienceDO = res.data.resumeProjectExperienceDO
                }
            })
        },
        //简历下载
        downLoadUserResume() {
            console.log(11)
            let downloadElement = document.createElement('a')
            downloadElement.href = this.resumeDO.enclosure
            document.body.appendChild(downloadElement)
            downloadElement.click()//点击下载
            document.body.removeChild(downloadElement) //下载完成移除元素

        },
        //收藏简历
        collectResumes() {
            companyRequest.collectResumes({resumeId: this.resumeId}).then((res) => {
                if (res.code === 200) {
                    this.$message.success('操作成功')
                    this.getResumeInfo()
                }
            }).catch((err) => {
                this.$message.error(err)
            })
        },
        //取消收藏简历
        deleteResume() {
            this.$confirm('此操作将取消收藏该简历, 是否继续?', '取消收藏', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                companyRequest.delCollectionResume({resumeId: this.resumeId}).then((res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: '取消收藏~'
                        })
                        this.getResumeInfo()
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })
        },
        //获取过滤后职位列表
        getFilterJobList() {
            companyRequest.getFilterJobList({resumeId: this.resumeId}).then((res) => {
                if (res.code === 200) {
                    this.jobList = res.data
                }
            })
        },
        //提交邀请面试
        invited() {
            this.$refs.inviteFormRef.validate((valid) => {
                if (valid) {
                    this.clickStatus = true
                    // console.log('inviteForm', this.inviteForm)
                    companyRequest.addInterview(this.inviteForm).then((res) => {
                        if (res.code === 200) {
                            this.$message.success('邀约面试成功~')
                            console.log(111)
                            this.getResumeInfo()
                            this.getFilterJobList()
                            this.inviteForm.userId = ''
                            this.inviteForm.userName = ''
                            this.inviteForm.workId = ''
                            this.inviteForm.resumeId = ''
                            this.inviteForm.beginTime = ''
                            this.inviteForm.address = ''
                            this.inviteForm.contacts = ''
                            this.inviteForm.type = 0
                            this.inviteForm.contactsPhone = ''
                            this.inviteVisible = false
                            setTimeout(() => {
                                this.clickStatus = false
                            }, 500)
                        }
                    }).catch((err)=> {
                        this.$message.error(err)
                        this.getResumeInfo()
                        this.getFilterJobList()
                        this.inviteForm.userId = ''
                        this.inviteForm.userName = ''
                        this.inviteForm.workId = ''
                        this.inviteForm.resumeId = ''
                        this.inviteForm.beginTime = ''
                        this.inviteForm.address = ''
                        this.inviteForm.contacts = ''
                        this.inviteForm.type = 0
                        this.inviteForm.contactsPhone = ''
                        this.inviteVisible = false
                        setTimeout(() => {
                            this.clickStatus = false
                        }, 500)
                    })
                } else {
                    // console.log('error submit!!')
                    // return false
                }
            })
        },
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
.resumbox{ max-height: 600px; overflow-y: auto;}
    pre{
        line-height: 20px;
    }
    // .no{
    //     text-align: center;
    //     margin-top: 350px;
    //     img{
    //         width: 40px;
    //     }
    //     p{
    //         font-size: 13px;
    //         margin-top: 15px;
    //     }
    // }
    .user-info{
        margin: 20px 60px;
        .headFigure{
            width: 150px;
            height: 150px;
            margin-right: 32px;
            margin-top: 14px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .base-info{
            color: #333;
            font-size: 14px;
            .certificate{
                padding: 15px 0 20px 0;
                span{
                    margin-right: 20px;
                    &.name{
                        font-weight: 700;
                    }
                }
                .el-icon-mobile-phone{
                    color: #f56c6c;
                    font-weight: 600;
                }
                .el-icon-folder-checked{
                    color: #5cb87a;
                    font-weight: 600;
                }
            }
            .base-detail{
                color: #666;
                font-size: 14px;
                // margin: 22px 0 18px;
                span{
                    margin-right: 10px;
                }
                .icon-split-small{
                    color: #dadada;
                    margin-right: 7px;
                }
            }
            .expectInfo{
                margin: 15px 0;
                .title-content{
                    font-size: 14px;
                    line-height: 28px;
                    .item-title{
                        color: #999;
                    }
                    .item-content{
                        color: #333;
                        margin-left: 10px;
                    }
                }
            }
            .getContact-row{
                button{
                    width: 150px;
                }
            }
        }
    }
    .title1{
        font-size: 20px;
        color: #333333;
        margin-bottom: 20px;
        font-weight: bold;
    }
    .industry{
        margin: 20px 60px;
        border-bottom: 1px solid #eee;
        .industry-title{
            font-size: 20px;
            color: #333333;
            margin-bottom: 41px;
            font-weight: bold;
        }
        .tag-choose{
            margin-bottom: 20px;
            .el-tag{
                margin: 0 10px 10px 0;
            }
        }
    }
    .work{
        margin: 20px 60px;
        border-bottom: 1px solid #eee;
        .work-detail{
            .itemName{
                font-size: 16px;
                line-height: 24px;
                color: #000;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .project-content{
                margin-bottom: 20px;
                p{
                    font-size: 14px;
                    line-height: 24px;
                    color: #333;
                    margin-bottom: 10px;
                }
            }
        }

    }
    .education{
        margin: 20px 60px;
        border-bottom: 1px solid #eee;
        .edu-detail{
            margin-bottom: 25px;
            .school{
                margin-bottom: 5px;
                font-size: 14px;
                .college-name{
                    font-weight: bold;
                    color: #000;
                }
                .splitLine-edu{
                    margin: 0 66px;
                    color: #d5d5d5;
                }
                .graduate-time{
                    // color: #999;
                    color: #333;
                }
            }
            .professional{
                margin: 8px 0;
                color: #000;
            }
        }
    }
    .aboutMe{
        margin: 20px 60px;
        border-bottom: 1px solid #eee;
        .about-detail{
            margin-bottom: 20px;
            line-height: 20px;
            font-size: 14px;
            color: #333;
        }
    }
    // .jobInform{
    //     align-items: center;
    //     background: #f4f5f7;
    //     color: #6f7a9a;
    //     font-size: 14px;
    //     height: 125px;
    //     padding-left: 60px;
    //     .latest-week2week{
    //         padding-right: 40px;
    //         border-right: 1px dashed #ccc;
    //         flex-direction: column;
    //         align-items: center;
    //         img{
    //             width: 28px;
    //             margin-bottom: 6px;
    //         }
    //     }
    //     .activety-level{
    //         margin-left: 60px;
    //         .level-icon{

    //         }
    //     }
    // }

</style>


